/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import "./UserForm.css";

import FooterV1 from "components/Footers/FooterV1.0.js";

// reactstrap components
import { FormGroup, Form, Input, Container, Row, Col, Alert } from "reactstrap";

import { Radio } from '@material-ui/core';

import {
  FORM_SEX, FORM_AGE, FORM_PROFESSION, FORM_YEARS_EDUC, FORM_LEVEL_EDUC,
  FORM_LEVEL_EDUC_INITIAL, FORM_LEVEL_EDUC_MIDDLE, FORM_LEVEL_EDUC_SUPERIOR,
  FORM_LEVEL_EDUC_DEFAULT, FEMALE_VALUE, MALE_VALUE, TEXT_FOOTER_ENTER
} from '../../../helpers/constants';

class UserForm extends React.Component {
  render() {
    return (
      <Container className="justify-content-center">
        <div className="text-center mt-2"><h3>Twoje dane</h3></div>
        <Alert style={{ fontSize: "1.0rem" }} color="warning" isOpen={this.props.error.showError}>
          <span className="alert-inner--text ml-1">
            {this.props.error.textError}
          </span>
        </Alert>
        <Form role="form" style={{ marginTop: '40px' }}>
          <FormGroup className="mb-3">
            <div className="d-flex align-items-left">
              <h5>Wiek</h5>
            </div>
            <Input id={FORM_AGE} placeholder="" autoFocus={true} onChange={this.props.action} type="text" value={this.props.data.age} />
          </FormGroup>
          <FormGroup className="mb-3">
            <div className="d-flex align-items-left">
              <h5>Zawód</h5>
            </div>
            <Input id={FORM_PROFESSION} placeholder="" onChange={this.props.action} type="text" value={this.props.data.profession} />
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-left">
              <h5>Poziom wykształcenia</h5>
            </div>
            <Input type="select" name="select" id={FORM_LEVEL_EDUC} onChange={this.props.action}>
              <option value={FORM_LEVEL_EDUC_DEFAULT}>Wybierz...</option>
              <option value={FORM_LEVEL_EDUC_INITIAL}>podstawowe</option>
              <option value={FORM_LEVEL_EDUC_MIDDLE}>średnie</option>
              <option value={FORM_LEVEL_EDUC_SUPERIOR}>wyższe</option>
            </Input>
          </FormGroup>
          <FormGroup className="mb-3">
            <div className="d-flex align-items-left">
              <h5>Lata formalnej edukacji <small><i>(tylko etapy kończące się formalnym świadectwem: podstawowe, średnie, wyższe: np 8 lat szkoły podstawowej + 4 lata liceum = 12 lat)</i></small></h5>
            </div>
            <Input id={FORM_YEARS_EDUC} placeholder="" onChange={this.props.action} type="text" value={this.props.data.yearsEduc} />
          </FormGroup>
          <FormGroup className="mb-3">
            <div className="d-flex align-items-left">
              <h5>Płeć</h5>
            </div>
            <Row >
              <Col lg="auto">
                <Radio
                  id={FORM_SEX}
                  checked={this.props.data.sex === FEMALE_VALUE}
                  onChange={this.props.action}
                  value={FEMALE_VALUE}
                  name={FORM_SEX + "_F"}
                  inputProps={{ 'aria-label': 'A' }}
                /> Kobieta
                </Col>
              <Col lg="auto">
                <Radio
                  id={FORM_SEX}
                  checked={this.props.data.sex === MALE_VALUE}
                  onChange={this.props.action}
                  value={MALE_VALUE}
                  name={FORM_SEX + "_M"}
                  inputProps={{ 'aria-label': 'A' }}
                /> Mężczyzna
                </Col>
            </Row>
          </FormGroup>
        </Form>

        <FooterV1 text={TEXT_FOOTER_ENTER} />
      </Container>
    );
  }
}

export default UserForm;
