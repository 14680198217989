/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Alert,
  Container
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <Container>
        <Alert color="success" style={{ color: "#212529", fontSize: "1.5em", textAlign: "center" }}>
          {this.props.text}
        </Alert>
      </Container>
    );
  }
}

export default SimpleFooter;
